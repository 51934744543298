import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id 
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				registerText: _rawRegisterText(resolveReferences: {maxDepth: 5})
				registerImage {
					...imageWithAlt
				}
				registerButton {
					...button
				}
				phoneNumber
				registerTitle
				emailAddress
      }
			sanityFooter {
				pageLinks {
					link{
						...link
					}
					text
				}
				signUpText
				facebookUrl
				instagramUrl
			}
    }
  `)

	return {...siteSettings?.sanitySiteSettings, ...siteSettings?.sanityFooter}
}

export default useSiteSettings
