import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import sendToMailchimp from '../utils/sendToMailchimp'
import {mobile, tablet} from '~styles/global'
import Button from '~components/Button'
import {SlideDown} from 'react-slidedown'

const NewsletterSignupForm = ({ className, successMessage, showName, center }) => {
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		sendToMailchimp(values.email, values.fname, values.lname, (err, data) => {
			if(err){
				console.log(err, data)
				setErrors([{
					field: null,
					message: 'Sorry we can\'t sign you up right now.'
				}])
			}
			else{
				setSuccess(true)
			}
		})
	}

	const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<Success 
				center={center}
				css={css`
					opacity: ${success ? '1' : '0'};
					pointer-events: ${success ? 'all' : 'none'};
      `}>
				{successMessage}
			</Success>
			<FormWrap css={css`
        opacity: ${success ? '0' : '1'};
        pointer-events: ${success ? 'none' : 'inherit'};
        `}>
				<Form onSubmit={e => validate(e)} noValidate>
					{showName &&
          <>
          	<Input
          		className='body'
          		type="text"
          		name="fname"
          		placeholder="First Name"
          		onChange={e => setValues({...values, fname: e.target.value})}
          	/>
          	<Input
          		className='body'
          		type="text"
          		name="lname"
          		placeholder="Last Name"
          		onChange={e => setValues({...values, lname: e.target.value})}
          	/>
          </>
					}

					<Input
						className='body'
						type="email"
						name="email"
						placeholder="Your email"
						css={css`
								border-color: ${errorFields.includes('email') ? 'var(--red)' : 'var(--black)'};
							`}
						onChange={e => setValues({...values, email: e.target.value})}
					/>
					<ErrorSlideDownMobile>
						{errors?.length > 0 &&
							<Errors className='sm-sans'>
								{errors.map(error => <p>{error.message}</p>)}
							</Errors>
						}
					</ErrorSlideDownMobile>
					<ButtonContainer>
						<SubmitButton color='blue' type="submit">
							Go
						</SubmitButton>
					</ButtonContainer>
				</Form>
				<ErrorSlideDown>
					{errors?.length > 0 &&
							<Errors className='sm-sans'>
								{errors.map(error => <p>{error.message}</p>)}
							</Errors>
					}
				</ErrorSlideDown>
			</FormWrap>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
`

const FormWrap = styled.div`
  position: relative;
  transition: opacity 0.3s;
  box-sizing: border-box;
	${mobile}{
		max-width: 100%;
	}
`
const Form = styled.form`
	display: flex;
	${mobile}{
		flex-wrap: wrap;
	}
`
const Input = styled.input`
	background: var(--white);
	border: none;
	width: 100%;
	box-sizing: border-box;
	font-size: inherit;
	padding: 0;
	border-radius: 5px;
	border: 1px solid #000;
	padding: 0 var(--m);
	height: 48px;
	margin-right: var(--m);
	font-family: inherit;
	::placeholder{
		color: var(--grey);
	}
	${mobile}{
		margin-right: 0;
	}
`
const Errors = styled.div`
	margin-top: var(--s);
`
const ErrorSlideDown = styled(SlideDown)`
	width: 100%;
	${mobile}{
		display: none;
	}
`
const ErrorSlideDownMobile = styled(ErrorSlideDown)`
	display: none;
	${mobile}{
		display: block;
	}
`
const Success = styled.div`
  position: absolute;
  top: 0;
  left: 0;
	right: ${props => props.center ? '0' : 'auto'};
  transition: opacity 0.3s 0.3s;
`
const ButtonContainer = styled.div`
	${tablet}{
		display: grid;
		align-items: center;
	}
	${mobile}{
		width: 100%;
	}
`
const SubmitButton = styled(Button)`
	align-self: center;
	button {
		min-width: 85px;
	}
	${mobile}{
		margin-top: var(--m);
	}
`

NewsletterSignupForm.propTypes = {
	successMessage: PropTypes.string,
	showName: PropTypes.bool,
}

export default NewsletterSignupForm
