module.exports = {
	siteUrl: 'https://goodsurf.gatsbyjs.io/',
	buildsUrl: 'https://dotgatsby58111.gtsb.io',
	previewUrl: 'https://preview-goodsurf.gtsb.io/',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/a09767e2-80c4-4822-97fc-703378139933',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-XXXXXXXXX'
}
