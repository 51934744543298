import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import GlobalStyles from '~styles/global'
import Header from '~components/Header'
import Cart from '~components/Cart'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile, tablet } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState, useCart } from '../context/siteContext'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import '~styles/static.css'
import { Helmet } from 'react-helmet'

const Layout = ({ children }) => {

	const [siteState, setSiteState] = useSiteState()
	const { closeCart } = useCart()
	const location = useLocation()
  
	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// Close the cart whenever the route changes
	useEffect(() => {
		closeCart()
		// eslint-disable-next-line
  }, [location])

	return (
		<>
			<GlobalStyles />
			<Header />
			{siteState.menuOpen &&
				<Global styles={css`
					html{
						overflow-y: hidden;
					}
				`}/>
			}
			<Cart open={siteState.cartOpen} />
			<main>
				<Transition>
					<div css={css`
            min-height: calc(100vh);
            display: flex;
            flex-direction: column;
            ${mobile}{
              min-height: calc(100vh);
            }
          `}>
						{children}
						<Footer css={css`margin-top: auto;`}/>
					</div>
				</Transition>
			</main>
			<Overlay 
				open={siteState.menuOpen}
				onClick={() => setSiteState(prevState => ({
					...prevState,
					menuOpen: false
				}))}
				css={css`
          pointer-events: ${siteState.menuOpen ? 'all' : 'none'};
      `}/>
		</>
	)
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	pointer-events: ${props => props.menuOpen ? 'all' : 'none'};
	background-color: var(--white);
	opacity: ${props => props.open ? '0.9' : '0'};
	z-index: 2;
	display: none;
	${mobile}{
		display: block;
	}
`

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
