import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, useStaticQuery, navigate } from 'gatsby'
import styled from '@emotion/styled'
import Section from '~components/Section'
import Button from '~components/Button'
import { mobile, tablet } from '~styles/global'
import { useSiteState } from '../context/siteContext'
import { Close, Hamburger, Logo } from '~components/Svg'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { useLocation } from '@reach/router'


const Header = ({ className }) => {

	const [siteState, setSiteState] = useSiteState()
	const loc = useLocation()

	useEffect(() => {
		setSiteState(prevState => ({
			...prevState,
			menuOpen: false,
		}))
	}, [loc.pathname])

	const data = useStaticQuery(graphql`
		{
			sanityBook {
				slug {
					current
				}
			}
			sanityRegister {
				slug {
					current
				}
			}
			sanityAbout {
				slug {
					current
				}
			}
			sanityProgramIndex {
				slug {
					current
				}
			}
		}
	`)
	
	const blueButton = () => {
		if ((loc?.pathname?.includes(data.sanityAbout?.slug?.current)) || (loc.pathname.includes(data.sanityRegister?.slug?.current))) {
			return true
		} else {
			return false
		}
	}

	const navigateAndClose = (url) => {
		navigate(`/${url}`)
		setSiteState(prevState => ({
			...prevState,
			menuOpen: false,
		}))
	}

	return(
		<Wrap className={className}>
			<Container>
				<LogoContainer homePage={loc.pathname === '/'}>
					<Link to={'/'}>
						<Logo />
						{/* <StaticImage 
							src='../images/Good_Support_Logo.png' 
							css={css`width: 100%;`} 
							alt='logo'
							placeholder="none"
						/> */}
					</Link>
				</LogoContainer>
				<NavDesk>
					<NavInner>
						<NavLink to={`/${data.sanityAbout?.slug?.current}`} activeClassName="active">About us</NavLink>
						<NavLink to={`/${data.sanityProgramIndex?.slug?.current}`} activeClassName="active">What&#39;s on</NavLink>
						<Button to={`/${data.sanityRegister?.slug?.current}`} color='blue'>Get Started</Button>
						{/* <NavButton to={`/${data.sanityBook?.slug?.current}`} color='blue'>Book online</NavButton> */}
					</NavInner>
					<MenuButton 
						onClick={() => setSiteState(prevState => ({
							...prevState,
							menuOpen: true,
						}))}>
						<OpenIcon />
								Menu
					</MenuButton>
				</NavDesk>
			</Container>
			<NavMob show={siteState.menuOpen}>
				<CloseBlock>
					<CloseButton onClick={() => setSiteState(prevState => ({
						...prevState,
						menuOpen: false,
					}))}>
						<CloseIcon />
					</CloseButton>
				</CloseBlock>
				<MobileNavLink onClick={() => navigateAndClose(data.sanityAbout?.slug?.current)} activeClassName="active">About us</MobileNavLink>
				<MobileNavLink onClick={() => navigateAndClose(data.sanityProgramIndex?.slug?.current)} activeClassName="active">What&#39;s on</MobileNavLink>
				<MobileNavLinkLast onClick={() => navigateAndClose(data.sanityRegister?.slug?.current)} color='white'>Get Started</MobileNavLinkLast>
				{/* <Button onClick={() => navigateAndClose(data.sanityBook?.slug?.current)} color='blue'>Book online</Button> */}
				<StaticImage 
					src='../images/illustrations/Mountains.png' 
					css={css`
						width: 100%; 
						margin-top: auto; 
						${mobile}{
							max-width: 400px;
							margin-left: auto;
					}`} 
					alt='mountains'
					placeholder="none"
				/>
			</NavMob>
		</Wrap>
	)
}

const Wrap = styled.header`
	padding: var(--l) 0;
	${mobile}{
		padding: var(--m) 0 var(--l) 0;
	}
`
const Container = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	padding: 0 40px;
	${mobile}{
		padding: 0 20px;
	}
`
const NavDesk = styled.nav`
	justify-self: end;
	${mobile}{
		align-self: center;
	}
`
const NavMob = styled.nav`
	display: none;
	position: fixed;
	z-index: 4;
	top: 0;
	bottom: 0;
	left: 127px;
	right: 0;
	padding: var(--m);
	background-color: var(--lightBlue);
	flex-direction: column;
	align-items: start;
	transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
	transition: transform 0.25s;
	${mobile}{
		display: flex;
	}
`
const CloseBlock = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-bottom: var(--xl);
`
const CloseButton = styled.button`
	
`
const CloseIcon = styled(Close)`
	width: 22px;
`
const NavInner = styled.div`
	display: flex;
	align-items: center;
	${mobile}{
		display: none
	}
`
const LogoContainer = styled.div`
	position: relative;
	z-index: 3;
	width: ${props => props.homePage ? '175px' : '110px'};
	transition: width 0.3s;
	transition-delay: 0.3s;
	${mobile}{
		width: 82px;
	}
`
const NavButton = styled(Button)`
	margin-left: var(--s);
`
const NavLink = styled(Link)`
	margin-right: var(--l);
	text-align: center;
	height: 48px;
	display: flex;
	align-items: center;
	position: relative;
	:after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--blue);
		height: 2px;
		opacity: 0;
		transition: opacity 0.4s;
		transition-delay: 0.3s;
		${mobile}{
			display: none;
		}
	}
	&.active {
		:after {
			opacity: 1;
		}
	}
`

const MobileNavLink = styled.button`
		margin-right: 0;
		margin-bottom: var(--m);
		height: auto;
`
const MobileNavLinkLast = styled(MobileNavLink)`
	margin-bottom: var(--xl);
`
const MenuButton = styled.button`
	display: none;
	${mobile}{
		display: flex;
		align-items: center;
	}
`
const OpenIcon = styled(Hamburger)`
	width: 15px;
	height: 12px;
	margin-right: var(--s);
	position: relative;
	top: 1px;
`
Header.propTypes = {
	className: PropTypes.string,
}

export default Header
