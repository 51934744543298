import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { mobile, tablet } from '~styles/global'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import SanityLink from '~components/SanityLink'
import MailchimpForm from '~components/MailchimpForm'
import { Insta, Facebook, Logo } from '~components/Svg'

const Footer = ({ className }) => {

	const { pageLinks, signUpText, facebookUrl, instagramUrl, phoneNumber, emailAddress } = useSiteSettings()

	return(
		<Wrap className={className}>
			<FooterMain >
				<LogoContainer>
					<Link to={'/'}>
						<Logo 
							css={css`width: 110px;${mobile}{width: 86px;}`}
						/>
						{/* <StaticImage 
							src='../images/Good_Support_Logo.png' 
							css={css`width: 100px;${mobile}{width: 76px;}`}
							alt='logo'
							placeholder="none"
						/> */}
					</Link>
				</LogoContainer>
				<Contact>
					<Tel href={`tel:${phoneNumber}`}>
						{phoneNumber}
					</Tel>
					<Email 
						href=""
						link={{
							url: `mailto:${emailAddress}`
						}}
					>
						{emailAddress}
					</Email>
					<Social>
						<SocialLink
							link={{
								linkType: 'external',
								url: instagramUrl
							}}
						>
							<Insta />
						</SocialLink>
						<SocialLink
							link={{
								linkType: 'external',
								url: facebookUrl
							}}
						>
							<Facebook />
						</SocialLink>
					</Social>
				</Contact>
				<FooterLinks>
					{pageLinks.map(page => (
						<FooterLink key={page.text} link={page.link}>{page.text}</FooterLink>
					))}
				</FooterLinks>
				<Subscribe>
					<SubMessage>{signUpText}</SubMessage>
					<SignUp successMessage={'Success!'}/>
				</Subscribe>
			</FooterMain>
			<Credits>
				<CreditsContainer>
					Website by <a href='https://formwork.build/' target='_blank' rel='noreferrer'>Formwork</a>
				</CreditsContainer>
			</Credits>
			{/* <DataViewer data={{ pageLinks, signUpText, facebookUrl, instagramUrl, phoneNumber, emailAddress }} name="page"/> */}
		</Wrap>
	)
}

const Wrap = styled.footer`
	background-color: var(--white);
	padding: var(--l) 0;
	${mobile}{
		padding-bottom: var(--m);
	}
`
const FooterMain = styled(Section)`
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: var(--l);
	}
`
const LogoContainer = styled.div`
	grid-column: span 2;
	${mobile}{
		grid-column: span 4;
	}
`
const Contact = styled.div`
	grid-column: span 3;
	${tablet}{
		grid-column: span 4;
	}
	${mobile}{
		grid-column: span 8;
		margin-bottom: var(--l);
	}
`
const Email = styled(SanityLink)`
	margin-bottom: var(--s);
	display: block;
	${mobile}{
		margin-bottom: var(--m);
	}
`
const Social = styled.div`
	display: flex;
`
const SocialLink = styled(SanityLink)`
	width: 29px;
	color: var(--blue);
	display: block;
	:first-of-type {
		margin-right: 15px;
	}
`
const Tel = styled.a`
	display: block;
`
const FooterLinks = styled.div`
	grid-column: span 2;
	${tablet}{
		display: none;
	}
`
const FooterLink = styled(SanityLink)`
	display: block;
`
const Subscribe = styled.div`
	grid-column: 9/13;
	${tablet}{
		grid-column: 7/13;
	}
	${mobile}{
		grid-column: span 12;
	}
`
const SubMessage = styled.div`
	margin-bottom: var(--s);
	${mobile}{
		margin-bottom: var(--m);
	}
`
const SignUp = styled(MailchimpForm)`
	
`
const Credits = styled(Section)`

`
const CreditsContainer = styled.div`
	grid-column: span 12;
	text-align: right;
	a {
		text-decoration: underline;
	}
	${mobile}{
		text-align: left;
	}
`
Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
