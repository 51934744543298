import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'
import { Play } from '~components/Svg'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

const Button = ({ className, children, link, onClick, color, play, to, download, type}) => {

	const [hover, setHover] = useState(false)

	const backgroundColor = () => {
		if (color === 'blue') {
			return 'var(--blue)'
		} else if (color === 'white') {
			return 'var(--white)'
		} else if (color === 'green') {
			return 'var(--green)'
		} else if (color === 'lightBlue') {
			return 'var(--lightBlue)'
		}
	}

	const buttonCss = css`
		display: flex;
		align-items: center;
		padding: var(--s) var(--m);
		height: 48px;
		box-sizing: border-box;
		border-radius: 50px;
		background-color: ${backgroundColor()};
		color: ${(color === 'white' || color === 'lightBlue')  ? 'var(--black)' : 'var(--white)'};
		text-align: center;
		justify-content: center;
		min-width: 157px;
		width: 100%;
		transition: background-color 0.3s;
		transition-delay: 0.3s;
		cursor: pointer;
		${tablet}{
			height: 45px;
		}
	`
	const variants={
		large: {scaleX: 1.08, scaleY: 1.1},
		regular: {scale: 1}
	}

	if (link) {
		return (
			<Relative 
				className={className}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<Enlarge
					animate={hover ? 'large' : 'regular'}
					variants={variants}
					transition={{default: { duration: 0.15 }}}
					bgColor={backgroundColor()}
				/>
				<Wrap 
					css={buttonCss}
					link={link}
					download={download}
				>
					<PlayIcon show={play}>{play && <Play/>}</PlayIcon>
					<Text>{children}</Text>
				</Wrap>
			</Relative>
		)
	} else if (to) {
		return (
			<Relative 
				className={className}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<Enlarge
					animate={hover ? 'large' : 'regular'}
					variants={variants}
					transition={{default: { duration: 0.15 }}}
					bgColor={backgroundColor()}
				/>
				<LinkWrap
					to={to}
					css={buttonCss}
				>
					<PlayIcon show={play}>{play && <Play/>}</PlayIcon>
					<Text>{children}</Text>
				</LinkWrap>
			</Relative>
		)
	} else if (onClick) {
		return (
			<Relative 
				className={className}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<Enlarge
					animate={hover ? 'large' : 'regular'}
					variants={variants}
					transition={{default: { duration: 0.15 }}}
					bgColor={backgroundColor()}
				/>
				<ButtonWrap
					type={type}
					css={buttonCss}
					onClick={onClick}
				>
					<PlayIcon show={play}>{play && <Play/>}</PlayIcon>
					<Text>{children}</Text>
				</ButtonWrap>
			</Relative>
		)
	} else {
		return (
			<Relative 
				className={className}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<Enlarge
					animate={hover ? 'large' : 'regular'}
					variants={variants}
					transition={{default: { duration: 0.15 }}}
					bgColor={backgroundColor()}
				/>
				<NoButtonWrap 
					css={buttonCss}
				>
					<PlayIcon show={play}>{play && <Play/>}</PlayIcon>
					<Text>{children}</Text>
				</NoButtonWrap>
			</Relative>
		)
	}

}

const Relative = styled.div`
	position: relative;
	display: inline-block;
`
const Enlarge = styled(motion.div)`
	background-color: ${props => props.bgColor};
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	border-radius: 50px;
	z-index: 0;
`
const Wrap = styled(SanityLink)`
	position: relative;
	z-index: 1;
`
const ButtonWrap = styled.button`
	position: relative;
	z-index: 1;
`
const LinkWrap = styled(Link)`
	position: relative;
	z-index: 1;
`
const NoButtonWrap = styled.button`
position: relative;
z-index: 1;
`
const PlayIcon = styled.div`
	width: ${props => props.show ? '23px' : '0px'};
	margin-right: ${props => props.show ? 'var(--s)' : '0'};
	display: inline-block;
	position: relative;
	height: 0px;
	> svg {
		position: absolute;
		top: 0;
		left: 0;
		transform: translateY(-50%);
		width: 20px;
		height: 23px; 
	}
`
const Text = styled.div`
	display: inline-block;
	margin-bottom: 1px;
`
Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.string,
	link: PropTypes.object,
	onClick: PropTypes.func,
	color: PropTypes.string,
	play: PropTypes.bool,
	to: PropTypes.string,
	download: PropTypes.bool,
	type: PropTypes.string,
}

export default Button